import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddPoolState } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import { PoolNameErrorHandler } from 'components/drawers/add-pool-drawer/pool-name-error-handler/pool-name-error-handler.class';
import { TranslationKey } from 'pipes/translate.pipe';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';

@Component({
  selector: 'app-add-pool-consumption-form',
  templateUrl: './add-pool-consumption-form.component.html',
  styleUrls: ['./add-pool-consumption-form.component.scss']
})
export class AddPoolConsumptionFormComponent implements OnInit {
  @Input() copyAlertFormData: any
  addPoolconsumptionform!: FormGroup
  @Output() getAlertCOnsumption = new EventEmitter()
  @Output() getformStatus = new EventEmitter()
  constructor(public fb: FormBuilder, private Addalertservice: AddAlertPoolDataService, private addPoolState: AddPoolState) { }

  ngOnInit(): void {
    this.Addalertservice.getFilterSubchildChanges(false);
    this.getformStatus.emit(false);
    this.addPoolconsumptionform = this.fb.group({
      getDatafromAlert: ['', [Validators.required, invalidCharactersValidator]],
      search_pool: ['', [Validators.required]],
      Threshold: ['', [Validators.required]],
      run_Frequency: ['', [Validators.required]],
    })
    this.Addalertservice.getcreatealerterrorMessageob$.subscribe((ele: any) => {
      this.addPoolconsumptionform.get('getDatafromAlert')?.setErrors({ apiChildError: ele });
    })
    this.addPoolconsumptionform.valueChanges.subscribe((ele: any) => {
      let getFromValuestatus: any = [];
      Object.values(this.addPoolconsumptionform.controls).forEach((ele: any) => {
        getFromValuestatus.push(ele.status);
        let getiterationvalue = getFromValuestatus.some((ele: any) => ele == "VALID");
        if (getiterationvalue) {
          this.getformStatus.emit(true);
        } else {
          this.getformStatus.emit(false);
          getFromValuestatus = [];
        }
      })
      this.addPoolconsumptionform.controls
      if (this.addPoolconsumptionform.get('getDatafromAlert')?.valid == true && this.addPoolconsumptionform.get('search_pool')?.value?.status == true && this.addPoolconsumptionform.get('Threshold')?.value?.status
        && this.addPoolconsumptionform.get('run_Frequency')?.value?.status == true) {
        this.getAlertCOnsumption.emit(this.addPoolconsumptionform.value);
      }
      else {
        this.getAlertCOnsumption.emit(null);
      }
    })
  }

  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.addPoolState.poolNameErrorHandler;
  }

  public poolNameErrorMessageKey(): TranslationKey {
    return 'error.invalidCharacter';
  }

}