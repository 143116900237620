import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import moment from 'moment';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { AddUTCToTimePipe } from 'pipes/add-utc-to-time.pipe';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { UTC_TIMES } from 'data/utc-times';
import { TranslatePipe } from 'pipes/translate.pipe';
import { take } from 'rxjs/operators';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { AddPoolConsumptionDialogboxComponent } from '../add-pool-consumption-dialogbox/add-pool-consumption-dialogbox.component';

@Component({
  selector: 'app-add-pool-consumption-searchpool',
  templateUrl: './add-pool-consumption-searchpool.component.html',
  styleUrls: ['./add-pool-consumption-searchpool.component.scss'],

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddPoolConsumptionSearchpoolComponent),
    multi: true
  }]
})
export class AddPoolConsumptionSearchpoolComponent implements ControlValueAccessor, OnInit {
  @Input() editformData: any;
  @Input() copyAlertFormData: any;
  searchfilter:boolean=false;
  poolStartDate: any;
  poolEndDate: any;
  alertStartDate: any;
  alertEndtDate: any;
  public suppressErrorState = new SuppressErrorMatcher();
  public datePickerErrorMatcher = new DatePickerErrorMatcher();
  public translations: { [key: string]: string } = {};
  public utcStartTimes = [...UTC_TIMES];
  public utcEndTimes = [...UTC_TIMES];
  private onTouched = () => { };
  private onChanged = (value: any) => { };
  startDatedata: any
  endDatedata: any
  disabled = false;
  todays = moment().format('YYYY-MM-DD');
  minStartDate: any
  poolId: any
  QueueId: any;
  addPoolConsumptionSearch!: FormGroup;
  patchData: any[] = [];
  startTimefetch: any;
  endTimeFetch: any;
  constructor(private fb: FormBuilder, private dialog: MatDialog, private getDialogueClosedData: AddAlertPoolDataService,
    private addUTCToTimePipe: AddUTCToTimePipe, private translatePipe: TranslatePipe,
  ) {
    this.translatePipe.loadTranslations(['label.queues', 'label.date']).pipe(take(1)).subscribe((translations) => {
      this.translations = translations;
    })
  };

  ngOnInit() {
    this.addPoolConsumptionSearch = this.fb.group({
      search: ['', [Validators.required]],
      Queue: ['', [Validators.required]],
      start: ['', [Validators.required]],
      EndDate: ['', [Validators.required]],
      isDateRangeCheck: [false, [Validators.required]],
      isTimeRangeCheck: [false, [Validators.required]],
      startTime: this.fb.array([this.getTimeData()])
    });
    this.getDialogueClosedData.getcreatealerterrorMessageob$.subscribe((ele: any) => {
      this.addPoolConsumptionSearch.get('start')?.setErrors({ apiError: ele })
    });
    this.addPoolConsumptionSearch.controls.Queue.disable();
    this.addPoolConsumptionSearch.controls.start.disable();
    this.addPoolConsumptionSearch.controls.EndDate.disable();
    this.addPoolConsumptionSearch.controls.startTime.disable();
    this.addPoolConsumptionSearch.controls.isDateRangeCheck.disable();
    this.addPoolConsumptionSearch.controls.isTimeRangeCheck.disable();
    this.addPoolConsumptionSearch.valueChanges.subscribe((ele: any) => {
      
      this.updatevalue(ele);
    });

    this.addPoolConsumptionSearch.get('startTime')?.valueChanges.subscribe((ele: any) => {
      this.addPoolConsumptionSearch.valid;
      this.updaedTimeData(ele);
    })
    this.addPoolConsumptionSearch.statusChanges.subscribe(() => {
      this.onChanged(this.addPoolConsumptionSearch.valid ? {
        'FormGroup': this.getRawValue(), 'status': this.addPoolConsumptionSearch.valid, 'poolId': this.poolId, 'QueueId': this.QueueId,
        'start': this.startDatedata, 'end': this.endDatedata, 'startTime': this.startTimefetch, 'endTIme': this.endTimeFetch
      } : false);
    });
  }



  writeValue(selected: any) {
    if (this.editformData != undefined) {
      this.setValuess()
    }
    if (this.copyAlertFormData != undefined) {
      this.setCopyConfiguration()
    }
  }

  setValuess() {
    this.addPoolConsumptionSearch?.controls.isDateRangeCheck.enable();
    this.addPoolConsumptionSearch?.controls.isTimeRangeCheck.enable();
    this.poolId = this.editformData[0].poolDetails.poolId;
    this.QueueId = this.editformData[0].queueId;
    this.addPoolConsumptionSearch?.get('search')?.patchValue(this.editformData[0].poolDetails.poolName);
    this.addPoolConsumptionSearch?.controls.Queue?.patchValue(this.getQueueDisplay(this.editformData[0]));
    this.startDatedata = this.editformData[0].poolDetails?.poolStartDate;
    this.endDatedata = this.editformData[0].poolDetails?.poolEndDate;
    if (this.editformData[0].isTimeRangeCheck) {
      this.startTimefetch = this.editformData[0].poolDetails.poolStartTime;
      this.endTimeFetch = this.editformData[0].poolDetails.poolEndTime;
      this.addPoolConsumptionSearch.controls.isTimeRangeCheck.setValue(true);
    } else if (this.editformData[0].isTimeRangeCheck == false) {
      this.addPoolConsumptionSearch.controls.isTimeRangeCheck.setValue(false);
    }
    this.startTimefetch = this.editformData[0].poolDetails.poolStartTime;
    this.endTimeFetch = this.editformData[0].poolDetails.poolEndTime;
    this.addPoolConsumptionSearch.controls.search.disable();
    this.addPoolConsumptionSearch.controls.Queue.disable();
    if (this.editformData[0].isDateRangeCheck) {
      this.startDatedata = this.editformData[0].poolDetails?.poolStartDate;
      this.endDatedata = this.editformData[0].poolDetails?.poolEndDate;
      this.addPoolConsumptionSearch.controls.isDateRangeCheck.setValue(true);
    }
    else {
      this.addPoolConsumptionSearch.controls.isDateRangeCheck.setValue(false);

    }
  }
  registerOnChange(fn: any) {
    this.onChanged = fn
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getRawValue() {
    let getrawValueofFromGroup = this.addPoolConsumptionSearch.getRawValue();
    return getrawValueofFromGroup;
  }

  setCopyConfiguration() {
    if (this.copyAlertFormData) {
      if (this.copyAlertFormData.Status.pool == true) {
        this.poolId = '';
        this.QueueId = '';
        let patchData = this.copyAlertFormData.Data.poolDetails;
        this.addPoolConsumptionSearch?.controls.isDateRangeCheck.enable();
        this.addPoolConsumptionSearch?.controls.isTimeRangeCheck.enable();
        this.addPoolConsumptionSearch.controls.start.enable();
        this.addPoolConsumptionSearch.controls.EndDate.enable();
        this.addPoolConsumptionSearch.controls.startTime.enable();
        this.poolId = patchData.poolId;
        this.QueueId = this.copyAlertFormData.Data.queueId;
        this.addPoolConsumptionSearch?.get('search')?.patchValue(patchData.poolName);
        this.addPoolConsumptionSearch?.controls.Queue?.patchValue(this.getQueueDisplays(this.copyAlertFormData?.Data));
      }
      if (this.copyAlertFormData.Status.dateRange == true) {
        let patchData: any = this.copyAlertFormData.Data.poolDetails
        this.startDatedata = patchData.poolStartDate;
        this.endDatedata = patchData.poolEndDate;
        if (this.copyAlertFormData.Data.isDateRangeCheck) {
          this.addPoolConsumptionSearch?.controls.isDateRangeCheck.enable();
          this.startDatedata = patchData.poolStartDate;
          this.endDatedata = patchData.poolEndDate;
          this.addPoolConsumptionSearch.controls.isDateRangeCheck.setValue(true);
        } else {
          this.addPoolConsumptionSearch?.controls.isDateRangeCheck.enable();
          this.addPoolConsumptionSearch.controls.isDateRangeCheck.setValue(false);
        }
      }
      if (this.copyAlertFormData.Status.timeRange == true) {
        this.addPoolConsumptionSearch?.controls.isTimeRangeCheck.enable();
        this.addPoolConsumptionSearch.controls.startTime.enable();
        this.startTimefetch = this.copyAlertFormData.Data.poolDetails.poolStartTime;
        this.endTimeFetch = this.copyAlertFormData.Data.poolDetails.poolEndTime;
        if (this.copyAlertFormData.Data.isTimeRangeCheck) {
          this.addPoolConsumptionSearch?.controls.isTimeRangeCheck.enable();
          this.startTimefetch = this.copyAlertFormData.Data.poolDetails.poolStartTime;
          this.endTimeFetch = this.copyAlertFormData.Data.poolDetails.poolEndTime;
          this.addPoolConsumptionSearch.controls.isTimeRangeCheck.setValue(true);
        } else {
          this.addPoolConsumptionSearch.controls.isTimeRangeCheck.setValue(false);
        }
      }
    }
  }

  getTimeData() {
    return this.fb.group({
      startTime: ['', [Validators.required,]],
      endTime: ['', [Validators.required,]],
    })
  }

  get FormDatasearchData() {
    return this.addPoolConsumptionSearch.get('startTime') as FormArray;
  }

  Duplicatethedata() {
    return this.FormDatasearchData.push(this.getTimeData());
  }

  removedatafromSearchPool(i: number) {
    if (this.FormDatasearchData.length > 1) {
      this.FormDatasearchData.removeAt(i);
    }
    else {
      const getThresholditemslist = this.FormDatasearchData.at(0);
      getThresholditemslist?.reset();
    }
  }

  get registerFormControl() {
    return this.addPoolConsumptionSearch.controls;
  }

  public openExamsListDialog(): void {
    if (!this.addPoolConsumptionSearch.get('search')?.value) {
      this.getDialogueClosedData.setDisableButton(-1);
      this.getDialogueClosedData.setDisabledButtonValue(true)
    }
    const loader = this.dialog.open(AddPoolConsumptionDialogboxComponent, {
      maxWidth: "81vw",
      width: "1350px",
      height: "700px",
      hasBackdrop: true,
      disableClose: true,
      data:this.searchfilter
    });
    loader.afterClosed().subscribe(ele => {
      if (ele != null) {
        this.searchfilter=true;
        this.getDialogueClosedData.setDisabledButtonValue(false)
        this.getDialogueClosedData.setDisableButton(ele?.index);
        this.startDatedata = "";
        this.endDatedata = "";
        this.startTimefetch = '';
        this.endTimeFetch = '';
        this.startDatedata = ele?.table.startDate;
        this.endDatedata = ele?.table.endDate;
        this.startTimefetch = ele?.table.startTime;
        this.endTimeFetch = ele?.table.endTime;
        this.addPoolConsumptionSearch.get('search')?.setValue(ele.table?.name);
        this.addPoolConsumptionSearch.controls.Queue.setValue(ele?.Queue);
        this.addPoolConsumptionSearch.controls.start.setValue(moment(ele?.table.startDate));
        this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(ele?.table.endDate));
        this.addPoolConsumptionSearch.controls.isDateRangeCheck.setValue(true);
        this.addPoolConsumptionSearch.controls.isTimeRangeCheck.setValue(true);
        this.FormDatasearchData.at(0).get('endTime')?.patchValue(ele?.table.endTime);
        this.FormDatasearchData.at(0).get('startTime')?.patchValue(ele?.table.startTime);
        this.poolId = ele.table.id;
        this.QueueId = ele.table.queueId;
        this.addPoolConsumptionSearch.controls.isDateRangeCheck.enable();
        this.addPoolConsumptionSearch.controls.isTimeRangeCheck.enable();
        this.addPoolConsumptionSearch.controls.Queue.disable();
        this.addPoolConsumptionSearch.controls.start.disable();
        this.addPoolConsumptionSearch.controls.EndDate.disable();
        this.addPoolConsumptionSearch.controls.startTime.disable();
        if (this.copyAlertFormData) {
          this.copyAlertFormData.Data.startDate = null;
          this.copyAlertFormData.Data.endDate = null;
          this.copyAlertFormData.Data.alertSchedule = [];
        }
      }
    })
  }

  public addUTCToTime(time: string): string {
    return this.addUTCToTimePipe.transform(time);
  }

  isformDirty() {
    let control1;
    let controol2;
    if (this.editformData || this.copyAlertFormData) {
      const lasttime = this.FormDatasearchData.at(this.FormDatasearchData.length - 1);
      control1 = lasttime.get('startTime')?.valid;
      controol2 = lasttime.get('endTime')?.valid;
    } else {
      const lasttime = this.FormDatasearchData.at(this.FormDatasearchData.length - 1);
      control1 = lasttime.get('startTime')?.dirty;
      controol2 = lasttime.get('endTime')?.dirty;
    }
    if (control1 && controol2) {
      return false;
    } else {
      return true;
    }
  }

  updaedTimeData(event: any) {
    const uniqueObjects = [];
    const seenStarts = new Set();
    for (const [index, obj] of event.entries()) {
      if (!obj || obj.startTime === null || obj.endTime === null || obj.startTime === "" || obj.endTime === "") {
      } else {
        if (obj.startTime >= obj.endTime) {
          const getsearchFromControls = this.FormDatasearchData.at(index);
          getsearchFromControls.get('startTime')?.setValue('');
          getsearchFromControls.get('endTime')?.setValue('');
          continue;
        }
        if (seenStarts.has(obj.startTime)) {
          const getsearchFromControls = this.FormDatasearchData.at(index);
          getsearchFromControls.get('startTime')?.setValue('');
          getsearchFromControls.get('endTime')?.setValue('');
          continue;
        }
        seenStarts.add(obj.startTime);
        uniqueObjects.push(obj);
      }}}

  updatevalue(event: any) {
    if (event?.isDateRangeCheck == false && this.addPoolConsumptionSearch.controls.start.status == 'DISABLED' && this.addPoolConsumptionSearch.controls.EndDate.status == 'DISABLED') {
      this.addPoolConsumptionSearch.controls.start.enable();
      this.addPoolConsumptionSearch.controls.EndDate.enable();
      this.addPoolConsumptionSearch.controls.start.reset();
      this.addPoolConsumptionSearch.controls.EndDate.reset();

      if (this.editformData != undefined) {
        this.addPoolConsumptionSearch.controls.start.setValue(moment(this.editformData[0].startDate));
        this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.editformData[0].endDate))
      }
      else {
        if (this.copyAlertFormData != undefined && this.copyAlertFormData.Status.dateRange == true) {
          this.addPoolConsumptionSearch?.controls.isDateRangeCheck.enable();
          this.addPoolConsumptionSearch.controls.start.enable();
          this.addPoolConsumptionSearch.controls.EndDate.enable();
          this.addPoolConsumptionSearch.controls.start.setValue(this.copyAlertFormData?.Data?.startDate == null ? null : moment(this.copyAlertFormData?.Data?.startDate));
          this.addPoolConsumptionSearch.controls.EndDate.setValue(this.copyAlertFormData?.Data?.endDate == null ? null : moment(this.copyAlertFormData?.Data?.endDate));
        }
      }
    } else if (event?.isDateRangeCheck == true && this.addPoolConsumptionSearch.controls.start.status == 'VALID' && this.addPoolConsumptionSearch.controls.EndDate.status == 'VALID') {
      this.addPoolConsumptionSearch.controls.start.disable();
      this.addPoolConsumptionSearch.controls.EndDate.disable();
      this.addPoolConsumptionSearch.controls.start.setValue(moment(this.startDatedata));
      this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.endDatedata));
    } else if (event?.isDateRangeCheck == true && this.addPoolConsumptionSearch.controls.start.status == 'VALID' && this.addPoolConsumptionSearch.controls.EndDate.status == 'INVALID') {
      this.addPoolConsumptionSearch.controls.start.disable();
      this.addPoolConsumptionSearch.controls.EndDate.disable();
      this.addPoolConsumptionSearch.controls.start.setValue(moment(this.startDatedata));
      this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.endDatedata));
    } else if (event?.isDateRangeCheck == true && this.addPoolConsumptionSearch.controls.start.status == 'INVALID' && this.addPoolConsumptionSearch.controls.EndDate.status == 'VALID') {
      this.addPoolConsumptionSearch.controls.start.disable();
      this.addPoolConsumptionSearch.controls.EndDate.disable();

      this.addPoolConsumptionSearch.controls.start.setValue(moment(this.startDatedata));
      this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.endDatedata));
    } else if (event?.isTimeRangeCheck == false && this.addPoolConsumptionSearch.controls.startTime.status == 'DISABLED') {
      this.addPoolConsumptionSearch.controls.startTime.enable();
      this.addPoolConsumptionSearch.get('startTime')?.reset();
      if (this.editformData != undefined) {
        this.editformData[0].alertSchedule.items.forEach((ele: any, index: any) => {

          this.FormDatasearchData.at(index).patchValue({ startTime: ele?.startTime, endTime: ele?.endTime })
          if (this.editformData[0].alertSchedule.items.length - 1 > index) {
            this.Duplicatethedata()
          }
        })
      }
      if (this.copyAlertFormData != undefined && this.copyAlertFormData.Status.timeRange == true) {
        this.copyAlertFormData.Data.alertSchedule.items.forEach((ele: any, index: any) => {
          this.FormDatasearchData.at(index).patchValue({ startTime: ele?.startTime, endTime: ele?.endTime })
          if (this.copyAlertFormData.Data.alertSchedule.items.length - 1 > index) {
            this.FormDatasearchData.push(this.getTimeData());
          }
        })
      }
    } else if (event?.isTimeRangeCheck == true && this.addPoolConsumptionSearch.controls.startTime.status == 'VALID') {
      let gettinglistlength = this.FormDatasearchData.length;
      if (gettinglistlength > 1) {
        for (let i = 0; i <= gettinglistlength; i++) {
          this.removedatafromSearchPool(this.addPoolConsumptionSearch.get('startTime')?.value.length - 1);
        }
      }
      this.addPoolConsumptionSearch.controls.startTime.disable();
      this.FormDatasearchData.at(0).get('startTime')?.patchValue(this.startTimefetch)
      this.FormDatasearchData.at(0).get('endTime')?.patchValue(this.endTimeFetch)
    } else {
      if (event?.isTimeRangeCheck == true && this.addPoolConsumptionSearch.controls.startTime.status == 'INVALID') {
        this.addPoolConsumptionSearch.controls.startTime.disable();
        this.FormDatasearchData.at(0).get('startTime')?.setValue(this.startTimefetch)
        this.FormDatasearchData.at(0).get('endTime')?.setValue(this.endTimeFetch)
        let gettinglistlength = this.FormDatasearchData.length;
        if (gettinglistlength > 1) {
          for (let i = 0; i <= gettinglistlength; i++) {
            this.removedatafromSearchPool(this.addPoolConsumptionSearch.get('startTime')?.value.length - 1);
            if (i == gettinglistlength) {
              this.FormDatasearchData.at(0).get('startTime')?.setValue(this.startTimefetch)
              this.FormDatasearchData.at(0).get('endTime')?.setValue(this.endTimeFetch)
            }
          }
        } else if (this.editformData) {
          this.FormDatasearchData.at(0).get('startTime')?.patchValue(this.editformData[0].poolDetails.poolStartTime)
          this.FormDatasearchData.at(0).get('endTime')?.patchValue(this.editformData[0].poolDetails.poolEndTime)
        } else if (this.copyAlertFormData) {
          this.FormDatasearchData.at(0).get('startTime')?.patchValue(this.copyAlertFormData.Data.poolDetails.poolStartTime)
          this.FormDatasearchData.at(0).get('endTime')?.patchValue(this.copyAlertFormData.Data.poolDetails.poolEndTime)
        }
      } else if (event?.isDateRangeCheck == true && this.addPoolConsumptionSearch.controls.start.status == 'INVALID' && this.addPoolConsumptionSearch.controls.EndDate.status == 'INVALID') {
        this.addPoolConsumptionSearch.controls.start.disable();
        this.addPoolConsumptionSearch.controls.EndDate.disable();
        this.addPoolConsumptionSearch.controls.start.setValue(moment(this.startDatedata == null || undefined ? this.copyAlertFormData.Data.poolDetails.poolStartDate : this.startDatedata));
        this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.endDatedata == null || undefined ? this.copyAlertFormData.Data.poolDetails.poolEndDate : this.endDatedata));
      } else if (event?.isDateRangeCheck == false && this.addPoolConsumptionSearch.controls.start.status == 'INVALID' && this.addPoolConsumptionSearch.controls.EndDate.status == 'INVALID') {
        if (this.editformData != undefined) {
          this.addPoolConsumptionSearch.controls.start.setValue(moment(this.editformData[0].startDate));
          this.addPoolConsumptionSearch.controls.EndDate.setValue(moment(this.editformData[0].endDate));
        }
      }
    }
  }

  getValidationcontrols(index: any) {
    let getFormArray = this.addPoolConsumptionSearch.get('startTime') as FormArray;
    const updateformGroup = getFormArray.controls[index] as FormGroup;
    return updateformGroup;
  }

  public getQueueDisplay(pool: any): string {
    const queue = this.editformData[1].find((q: any) => q.id === pool.queueId);
    return queue.name;
  }

  getQueueDisplays(pool: any) {
    const queue = this.copyAlertFormData.queue.find((q: any) => q.id === pool.queueId);
    return queue.name;
  }

  inInput(event: KeyboardEvent) {
    event.preventDefault();
  }
}
