import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { publish } from 'rxjs/operators';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';

import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ClientInfo, GetPoolsParameters, PoolStatus } from 'api/types';
import { invalidCharactersRegex } from 'constants/invalid-characters-regex';
import { TranslatePipe } from 'pipes/translate.pipe';
import { AllPoolsFiltersService, GetPoolFilterChip, GetPoolsFilterChipType } from 'services/all-pools-filters.service';
import { MultiSelectOption } from 'components/filters/filter-multi-select/filter-multi-select.component';
import { AllAlertFiltersService } from 'services/all-alert-filters.service';

export const initialStatusOptions: MultiSelectOption[] = [
  { display: '', value: 'completed', checked: false },
  { display: '', value: 'archived', checked: false },
  { display: '', value: 'open', checked: false },
  { display: '', value: 'scheduled', checked: false },
];

@Component({
  selector: 'app-alert-consumption-table-filter',
  templateUrl: './alert-consumption-table-filter.component.html',
  styleUrls: ['./alert-consumption-table-filter.component.scss']
})
export class AlertConsumptionTableFilterComponent implements OnInit {
@Input() alertFilterStatus:any
@Output() changesData= new EventEmitter<any>();
  public initialSearchTerm = '';
  private mySuscription!:Subscription;

  /**
   * Array of status options passed to multi select component
   */
  public statusOptions: MultiSelectOption[] = initialStatusOptions;

  /**
   * Array of filter objects that represent what is shown as dismissible chips
   */
  public selectedFilters: GetPoolFilterChip[] = [];

  /**
   * Characters blacklisted from search field
   */
  public invalidRegex = invalidCharactersRegex;

  private destroyed$ = new Subject();

  constructor(
    private getDialogueClosedData: AddAlertPoolDataService, private translatePipe: TranslatePipe,
    private allPoolsFilterService: AllAlertFiltersService) { 
      this.translatePipe.loadTranslations([
        'label.completed',
        'label.archived',
        'label.open',
        'label.scheduled'
      ])
        .pipe(take(1))
        .subscribe((translations) => {
          this.statusOptions = this.statusOptions.map((status) => {
            return {
              ...status,
              display: translations[ `label.${status.value}` ]
            };
          });
        });
    }

    public ngOnInit(): void {
      console.log(this.alertFilterStatus);
      
      this.allPoolsFilterService.params$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((filters) => {
          this.initialSearchTerm = filters.searchTerm || '';
  
          if (filters.status) {
            // Update statuses based on service params
            this.updateStatuses(filters.status);
          }
        });
  
         this.allPoolsFilterService.selectedFilters$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((selectedFilters) => {
          this.selectedFilters = [ ...selectedFilters ];
          
        });
    }
  
    public ngOnDestroy(): void {
      // this.mySuscription.unsubscribe()
      this.destroyed$.next();
      this.destroyed$.complete();
    }

 

    /**
   * Handles when user clicks "apply" on status filter
   *
   * @param selectedStatuses array of selected statuses
   */
    public statusSelectionUpdated(selectedStatuses: MultiSelectOption[]): void {
      this.getDialogueClosedData.setDisableButton(-1)
      this.getDialogueClosedData.setDisabledButtonValue(true)
      // Get values of selectedStatuses then filter based on accepted status types
      const selectedStatus = selectedStatuses
        .map((status) => status.value)
        .filter((status): status is PoolStatus => this.isValidStatus(status));
  
      // Updated filters
      this.allPoolsFilterService.updateParams({ status: selectedStatus });
    }
  
    /**
     * Handles when user clicks "apply" on client filter
     *
     * @param selectedClients array of selected clients
     */
    public clientSelectionUpdated(selectedClients: ClientInfo[] ,status:any): void {
      console.log(selectedClients,status);
      this.changesData.emit(selectedClients == undefined ? null :selectedClients)
      
      this.getDialogueClosedData.getFilterSubchildChanges(true)
      
      if(status == 'moral'){
      this.getDialogueClosedData.setDisabledButtonValue(true)
      this.allPoolsFilterService.addClientsToFilter(selectedClients);
      }
      else{
      // this.getDialogueClosedData.setDisableButton(-1)
      
        this.getDialogueClosedData.setDisabledButtonValue(true)
     
      
      this.allPoolsFilterService.addClientsToFilter(selectedClients);
      }
      
      
    }
  
    /**
     * Callback to wire between the FilterByComponent and AllPoolsFiltersService
     *
     * @param removedFilter filter to remove
     */
    public filterRemoved(removedFilter: GetPoolsFilterChipType): void {
     if(!this.alertFilterStatus){
      this.getDialogueClosedData.setDisabledButtonValue(true)
     }else{
      this.getDialogueClosedData.setDisabledButtonValue(false)
     }
      
      switch (removedFilter.filterKey) {
        case 'status':
          this.allPoolsFilterService.removeStatus(removedFilter.value as PoolStatus);
          break;
        case 'clientIds':
          this.allPoolsFilterService.removeClient(removedFilter.value as string);
          break;
        case 'examIds':
          this.allPoolsFilterService.removeExam(removedFilter.value as string);
          break;
        case 'queueIds':
          this.allPoolsFilterService.removeQueue(removedFilter.value as string);
          break;
        case 'after':
        case 'before':
          this.allPoolsFilterService.removeDate(removedFilter.filterKey);
          break;
      }
    }
  
    /**
     * Updates search term
     *
     * @param inputValue value from search input
     */
    public updateSearchParams(inputValue: string): void {
      this.changesData.emit(inputValue == undefined ? null :inputValue)
     this.getDialogueClosedData.setDisableButton(-1)
     this.getDialogueClosedData.setDisabledButtonValue(true)
      
      const newParams: Partial<GetPoolsParameters> = {
        searchTerm: inputValue,
      };
      this.allPoolsFilterService.updateParams(newParams);
    }
  
    /**
     * Update local status object to match params
     * Passed to FilterMultiSelectComponent
     *
     * @param statuses updated statuses that are selected
     */
    private updateStatuses(statuses: PoolStatus[]): void {
      this.statusOptions = this.statusOptions.map((statusOption) => {
        const isSelected = (statuses as string[]).includes(statusOption.value);
  
        return {
          ...statusOption,
          checked: isSelected
        };
      });
    }
  
    /**
     * Makes typescript happy with string to PoolStatus conversion and won't allow
     * non-acceptable status to be passed to the backend
     *
     * @param status status to validate against
     * @returns true if status is valid
     */
    private isValidStatus(status: string): status is PoolStatus {
      return [ 'completed', 'archived', 'open', 'scheduled' ].includes(status);
    }

}
