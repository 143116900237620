
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddPoolState } from 'components/drawers/add-pool-drawer/add-pool-state/add-pool-state.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { CalendarOccuranceService } from 'services/api/calendar-occurance.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { RequestStatus } from 'types/RequestStatus';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';

@Component({
  selector: 'app-alert-resolve',
  templateUrl: './alert-resolve.component.html',
  styleUrls: ['./alert-resolve.component.scss']
})
export class AlertResolveComponent implements OnInit {
  headingDate:any
  occuranceStatusforapply: any
  occurrenceForm!: FormGroup
  poolName: boolean = false;
  dateRange: boolean = false;
  timeRange: boolean = false;
  threshold: boolean = false;
  runFrequency: boolean = false;
  private error?: DisplayableServerError | null;
  public status: RequestStatus = 'initial';
  secondHalfHourlyControls = []
  firstHalfHourlyControls = [1, 2, 3, 4, 5, 6, 7, 8]
  patchData: any[] = [];
  private destroyed$ = new Subject();
  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    @Inject(MAT_DIALOG_DATA) public data: AlertResolveInputs,
    private dialogRef: MatDialogRef<AlertResolveComponent>, private fb: FormBuilder,
    private capacityapi: CalendarOccuranceService,
     private drawerStatusService: DrawerStatusService,
      private addPoolStates: AddPoolState , private addAlertConsumptionCreate: AddAlertPoolDataService,) { }


  public closeDialog(): void {
    this.dialogRef.close(null);
  }

  ngOnInit(): void {
    const date=moment(this.data.data.date)
    this.headingDate=date.format('MMMM DD, YYYY')
    this.drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;
      });

    let fetchdatafromcapacity = this.data.data;
    this.occurrenceForm = this.fb.group({
      occuranceStatus: [''],
      timeSlots: this.fb.array([this.updateTimeSlotsData()])
    });
    fetchdatafromcapacity.slotsWiseCapacity.forEach((data: any, index: any) => {
      this.occurrenceTimeSlots.at(index).get('slot')?.patchValue(data.slot)
      this.occurrenceTimeSlots.at(index).get('netQueueAvailability')?.patchValue(data.netQueueAvailability)
      this.occurrenceTimeSlots.at(index).get('poolAvailability')?.patchValue(data.poolAvailability)
      this.occurrenceTimeSlots.at(index).get('poolCapacity')?.patchValue(data.poolCapacity)
      this.occurrenceTimeSlots.at(index).get('activeOccHourFlag')?.patchValue(data.activeOccHourFlag)
      this.occurrenceTimeSlots.at(index).get('activeAlertHourFlag')?.patchValue(data.activeAlertHourFlag)

      if (fetchdatafromcapacity.slotsWiseCapacity.length - 1 > index) {
        this.occurrenceTimeSlots.push(this.updateTimeSlotsData());
        this.editData(index);
      }
    });
    this.occurrenceForm.controls.occuranceStatus.patchValue(fetchdatafromcapacity.occrStatus);
    if (fetchdatafromcapacity.occrStatus === 'open' && this.data.status == '0' || fetchdatafromcapacity.occrStatus === 'scheduled' && this.data.status == '0' ) {
      this.occurrenceForm.get('timeSlots')?.enable();
      this.occuranceStatusforapply = false;
    }
    else {
      this.occurrenceForm.get('timeSlots')?.disable();
      this.occuranceStatusforapply = true;
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get displayableServerError(): DisplayableServerError | null | undefined {
    return this.addPoolStates.displayableServerError || this.error;
  }

  editData(index: any) {
    let getthresholdgettercontrols = this.occurrenceForm.get('timeSlots') as FormArray;
    const getthresholdgetterFromGroup = getthresholdgettercontrols.controls[index] as FormGroup;
    return getthresholdgetterFromGroup;
  }

  handleSubmit() {
    const payload = {
      "date": this.data.data.date,
      "occrStatus": this.data.data.occrStatus,
      "slotsWiseCapacity": this.occurrenceForm.get('timeSlots')?.value
    }
    this.capacityapi.updateCalendarCapacity(this.data.id, payload).subscribe((res: any) => {
      this.error = null;
      this.addAlertConsumptionCreate.triggerRefresh();
      this.drawerStatusService.success();
      this.closeDialog();
    }
      , (error: unknown) => {
        this.error = getDisplayableServerError(error);
        this.drawerStatusService.error();
        this.closeDialog();
      })
  }

  updateTimeSlotsData() {
    return this.fb.group({
      slot: [''],
      netQueueAvailability: [''],
      poolAvailability: [''],
      activeOccHourFlag: [''],
      activeAlertHourFlag: [''],
      poolCapacity: ['',Validators.required]
    })
  }

  get occurrenceTimeSlots() {
    return this.occurrenceForm.get('timeSlots') as FormArray;
  }

}

export const alertResolveModalConfig = {
  ...VUE_DIALOG_CONFIG,
  width: '40rem',
  height: 'auto'
};

export interface AlertResolveInputs {
  data: any
  id: any,
  status:any,

}
