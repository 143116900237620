import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { utc } from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ExamName,
  GetQueuesResponse
} from 'api/types';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT, FULL_MONTH_DAY_YEAR_FORMAT } from 'constants/date-formats';
import { QueuesService } from 'services/api/queues.service';
import { PageStatusService } from 'services/status/page-status.service';
import { LOADING } from 'types/RequestStatus';
import { AlertScheduleItems, ClientName } from 'api/types/endpoints/getPoolConsumption';
import { AlertsService } from 'services/api/alerts.service';
import { GetAlertByIdResponse } from 'api/types/endpoints/getAlertById';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';
import { resolve } from 'components/filters/alert-resolve-filters/alert-resolve-filter-config';



/**
 *  Pool consumption details page
 */
@Component({
  selector: 'app-alert-details-page',
  templateUrl: './alert-details-page.component.html',
  styleUrls: ['./alert-details-page.component.scss']
})
export class AlertDetailsPageComponent implements OnInit, OnDestroy {
  public alertDetails: GetAlertByIdResponse = {} as GetAlertByIdResponse;

  public formattedAlertStartDate = '';
  public formattedAlertEndDate = '';

  public queueName = '';

  public formattedResolvedOn = '';

  public formattedCreatedOn = '';

  public resolvedBy = '';

  public pageTitle = '';

  public examsToDisplay: ExamName[] = [];

  public alertTimeRangeToDisplay: AlertScheduleItems[] = [];

  public displayAllExams = false;

  public displayAllAlertTimeRange = false;

  public showOccurrenceActionError = false;

  public pageLoading = false;

  private destroyed$ = new Subject();

  public poolName: string = '';

  public clients: ClientName[] = [];

  public constructor(
    private queuesService: QueuesService,
    private route: ActivatedRoute,
    private pageStatusService: PageStatusService,
    private alertsService: AlertsService,
    private router: Router,
    private Alertservices: AddAlertPoolDataService
  ) {
  }

  public ngOnInit(): void {
    // Listen for page status changes
    this.pageStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
      });

    this.fetchPageData();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public moreThanFiveExams(): boolean {
    return (this.alertDetails?.poolDetails.exams?.length || 0) > 5;
  }

  public moreThanFiveAlertTimeRange(): boolean {
    return (this.alertDetails?.pc_Schedule?.items.length || 0) > 5;
  }

  public toggleExamsDisplay(): void {
    this.displayAllExams = !this.displayAllExams;
    this.examsToDisplay = this.displayAllExams ? this.alertDetails.poolDetails.exams : this.alertDetails.poolDetails.exams.slice(0, 5);
  }

  public toggleAlertTimeRangeDisplay(): void {
    this.displayAllAlertTimeRange = !this.displayAllAlertTimeRange;
    this.alertTimeRangeToDisplay = this.displayAllAlertTimeRange ? this.alertDetails.pc_Schedule.items : this.alertDetails.pc_Schedule.items.slice(0, 5);
  }

  public refreshAlertsDetails(): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading();

    this.alertsService.getAlertsById({ id: id })
      .pipe(take(1))
      .subscribe((alerts: any) => {
        this.alertDetails = alerts;
        this.pageStatusService.success();
      }, () => {
        this.pageStatusService.error();
      });
  }

  private fetchPageData(): void {
    const id = this.route.snapshot.params.id ?? '';

    this.pageStatusService.loading();

    this.alertsService.getAlertsById({ id: id })
      .pipe(switchMap((pool) => {
        return forkJoin([
          of(pool),
          this.queuesService.getQueues()
        ]);
      }), take(1)).subscribe((responses) => {
        const [pool, queues] = responses;
        this.callsSucceeded(pool, queues);
      }, () => {
        this.pageStatusService.error();
      });
  }

  private callsSucceeded(
    alertDetails: GetAlertByIdResponse, queues: GetQueuesResponse): void {
    const currentQueue = queues.find((queue) => queue.id === alertDetails.queueId);
    const exams: any[] = alertDetails.poolDetails.exams;
    const alertTimeRange: any[] = alertDetails.pc_Schedule.items;

    this.alertDetails = alertDetails;

    this.poolName = alertDetails.poolDetails.poolName;

    this.clients = alertDetails.poolDetails.clients;

    this.queueName = currentQueue?.name ?? '';

    this.examsToDisplay = exams.length > 5 ? exams.slice(0, 5) : exams;

    this.alertTimeRangeToDisplay = alertTimeRange.length > 5 ? alertTimeRange.slice(0, 5) : alertTimeRange;

    this.formattedAlertStartDate = utc(alertDetails.startDate).format(FULL_MONTH_DAY_YEAR_FORMAT);
    this.formattedAlertEndDate = utc(alertDetails.endDate).format(FULL_MONTH_DAY_YEAR_FORMAT);

    this.formattedCreatedOn = alertDetails.createdOn ? this.formatDate(alertDetails.createdOn) : '-';

    this.formattedResolvedOn = alertDetails.resolvedOn ? utc(alertDetails.resolvedOn).format(FULL_MONTH_DAY_YEAR_FORMAT) : '-';
    this.resolvedBy = alertDetails.resolvedBy ? alertDetails.resolvedBy : '-';

    this.pageTitle = alertDetails.name + " " + alertDetails.id;

    this.pageStatusService.success();
  }

  public formatDate(date: string): string {
    const splitDate = date.split('T');
    const splitTime = splitDate[1];
    const shortTime = splitTime.slice(0, 5);

    return date ? utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT) + " at " + shortTime : '';
  }

  OpenCalendar() {
    let data = {
      id: this.alertDetails.id,
      name: this.alertDetails.name,
      status:this.alertDetails.status == 'Resolved' ? 1 : 0

    };
    resolve(this.alertDetails);
    this.Alertservices.getcalendarDataobject(this.alertDetails);
   
    
    this.router.navigate([`alerts/${this.route.snapshot.params.id}/resolve`], { queryParams: { "data": JSON.stringify({ key: data }) } })
  }
}
